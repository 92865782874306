import { useCurrencyStore } from '@/stores/currency';

export function useApiContinents() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getAllContinents = async (propertyType) => {
    let result = ref(null);

    const callKey = `getAllContinents${locale?.value}${propertyType}${userCurrency?.code}`;
    const endpoint = `continents/${propertyType}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getContinentByName = async (propertyType, continentName) => {
    let result = ref(null);

    const callKey = `getContinentByName${locale?.value}${propertyType}${continentName}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentName}/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getContinentCountries = async (propertyType, continentId) => {
    let result = ref(null);

    const callKey = `getContinentCountries${locale?.value}${propertyType}${continentId}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentId}/countries/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data?.countries || null;
  };

  const getAverageCityPricesByContinent = async (propertyType, continentId) => {
    let result = ref(null);
    const callKey = `getAllContinents${locale?.value}${propertyType}${continentId}${userCurrency?.code}`;
    const endpoint = `continent/${propertyType}/${continentId}/city-prices/`;

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, defaultQuery, defaultParams));

    return result?.value?.data || null;
  };

  return {
    getAllContinents,
    getContinentByName,
    getContinentCountries,
    getAverageCityPricesByContinent,
  };
}
